import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../fonts/fonts.css'

const theme = {
  colorWhite: '#fff',
  colorBlack: '#000',
  colorOrange: '#fdb300',
  colorOrangeRed: '#e1461a',
  colorPeachLight: '#faeede',
  colorPeachGray: '#f8eee1',
  colorGray: '#f7f7f7',
  colorGrayDark: '#dedede',

  colorCherry: '#a34544',
  colorCherryBlack: '#8c373a',
  colorOrangeDark: '#f8ae01',
  colorOrangeBlack: '#e77321',
  colorRedDark: '#d14804',
  colorGold: '#ffc621',
  colorGoldDark: '#eeb100',

  fontOpenSans: `"Open Sans", sans-serif;`,
  fontIntro: `"Intro", sans-serif;`,
  fontHelveticaNeue: `"Helvetica Neue", "Helvetica", "Arial", sans-serif;`,
}

const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
  }
  body {    
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${theme.colorPeachLight};
    color: ${theme.colorBlack};
    font-size: 16px;
    font-family: ${theme.fontOpenSans};
    font-weight: 400;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }

  a {
    transition: ease 0.2s all;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .container {
    @media (min-width: 1600px) {
      max-width: 1518px;
    }
  }
`

const Layout = (props) => {
  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
